import AboutMe from "../AboutMe";
import ContactMe from "../ContactMe";
import Footer from "../Footer";
import HeroSection from "../HeroSection";
import U17 from "../U17";
import Pc30 from "../Pc30";
import Pc60 from "../Pc60";
import Pc120 from "../Pc120";
import Pc200 from "../PC200";
import Dump from "../Dump";

export default function Home() {
  return (
    <>
      <HeroSection />
      <AboutMe />
      <U17 />
      <Pc30 />
      <Pc60 />
      <Pc120 />
      <Pc200 />
      <Dump />
      <ContactMe />
      <Footer />
    </>
  );
}
