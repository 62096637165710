export default function Pc200() {
    return (
      <section id="AboutMe" className="about--section">
        <div className="hero--section--content--box about--section--box">
          <div className="hero--section--content">
            <p className="section--title">แบคโคใหญ่</p>
            <h1 className="skills-section--heading">PC200</h1>
            <p className="hero--section-description">
            รถแบคโฮขนาด PC200 พร้อมบูมยาวเป็นรถขุดขนาดใหญ่ที่มีบูมยาวเป็นพิเศษ ทำให้สามารถเข้าถึงและทำงานในพื้นที่ที่อยู่ไกลจากตัวรถได้ดีขึ้น เหมาะสำหรับงานที่ต้องการการขุดลึกและการเข้าถึงในระยะทางไกล โดยทั่วไปแล้ว รถแบคโฮรุ่น PC200 บูมยาวสามารถทำงานดังต่อไปนี้ได้:
            ขุดลึกและขุดในระยะทางไกล, ขุดในพื้นที่ที่เข้าถึงยาก, ปรับพื้นที่ขนาดใหญ่, รื้อถอนโครงสร้างสูง, งานในแหล่งน้ำ, 
            ย้ายดินและวัสดุในปริมาณมาก, งานก่อสร้างและเหมือง, ติดตั้งท่อและระบบใต้ดินขนาดใหญ่
            </p>
            <p className="hero--section-description">
            แบคโฮ (รถแม็คโคร) ขนาดรุ่น PC200 บริการทั้งแบบรายวันและรายเเดือน 
            พร้อมทั้งคนขับรถที่มีประสบการณ์ ปรึกษาได้ตลอด 24 ชั่วโมง
            โทร.<a href="tel:0876813472">0876813472</a>และ<a href="tel:0922736082">0922736082</a>
            </p>
          </div>
        </div>
        <div className="about--section--img">
        <video width="358" height="636" controls autoPlay loop>
          <source src="../img/pc200.webm" type="video/webm" />
          <source src="../img/pc200.mp4" type="video/mp4" />
          
        </video>
      </div>
      </section>
    );
  }
  