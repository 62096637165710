export default function U17() {
    return (
      <section id="AboutMe" className="about--section">
        <div className="hero--section--content--box about--section--box">
          <div className="hero--section--content">
            <p className="section--title">แบคโคเล็ก</p>
            <h1 className="skills-section--heading">U17</h1>
            <p className="hero--section-description">
            รถแบคโฮเล็ก รุ่น U17 เป็นรถแบคโฮหรือแม็คโคร ขนาดเล็กที่เข้าถึงพื้นที่แคบๆได้เป็นอย่างดี
              มีกำลังขุดที่เกินตัว มีเสียงที่ไม่ดังเกินไป สามารถขุดเจาะ กระแทกได้เหมือนแบคโฮรุ่นใหญ่ๆ 
              เหมาะงานเล็กจนถึงขนาดกลาง เคลื่อนย้ายได้ง่าย คล่องตัวในทุกพื้นที่ที่จำกัด
            </p>
            <p className="hero--section-description">
              แบคโฮ (รถแม็คโคร) ขนาดเล็กรุ่น u17 บริการทั้งแบบรายวันและรายเเดือน 
              พร้อมทั้งคนขับรถที่มีประสบการณ์ ปรึกษาได้ตลอด 24 ชั่วโมง
              โทร.<a href="tel:0876813472">0876813472</a>และ<a href="tel:0922736082">0922736082</a>
            </p>
          </div>
        </div>
        <div className="about--section--img">
          <img src="./img/u17.webp" alt="U17" />
        </div>
      </section>
    );
  }
  