export default function PC120() {
    return (
      <section id="AboutMe" className="about--section">
        <div className="about--section--img">
          <img src="./img/pc120.webp" alt="แบคโฮ PC120" />
        </div>
        <div className="hero--section--content--box about--section--box">
          <div className="hero--section--content">
            <p className="section--title">แบคโฮ</p>
            <h1 className="skills-section--heading">PC120</h1>
            <p className="hero--section-description">
            รถแบคโฮขนาด PC120 เป็นรถขุดขนาดใหญ่ที่มีประสิทธิภาพสูงและความสามารถหลากหลายในงานก่อสร้างและงานเกษตรกรรม สามารถทำงานที่ต้องการกำลังมากและการขุดลึกในปริมาณมากขึ้น โดยทั่วไปแล้ว รถแบคโฮรุ่น PC120 สามารถทำงานดังต่อไปนี้ได้:
            ขุดดินลึกและปริมาณมาก, ปรับพื้นที่ขนาดใหญ่, ย้ายดินและวัสดุในปริมาณมาก, 
            งานรื้อถอนขนาดใหญ่, งานเกษตรและป่าไม้ขนาดใหญ่, ขุดเจาะและงานเหมือง, งานติดตั้งท่อและระบบใต้ดิน รถแบคโฮรุ่น PC120 
            มีขนาดใหญ่และพลังงานมาก ทำให้สามารถทำงานหนักและปริมาณมากได้อย่างมีประสิทธิภาพ 
            เหมาะสมกับงานที่ต้องการความสามารถในการขุดและย้ายวัสดุในปริมาณมาก และงานก่อสร้างขนาดใหญ่
            </p>
            <p className="hero--section-description">
            แบคโฮ (รถแม็คโคร) ขนาดรุ่น PC60 บริการทั้งแบบรายวันและรายเเดือน 
            พร้อมทั้งคนขับรถที่มีประสบการณ์ ปรึกษาได้ตลอด 24 ชั่วโมง
            โทร.<a href="tel:0876813472">0876813472</a>และ<a href="tel:0922736082">0922736082</a>
            </p>
          </div>
        </div>
      </section>
    );
  }
  