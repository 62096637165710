export default function Pc60() {
    return (
      <section id="AboutMe" className="about--section">
        <div className="hero--section--content--box about--section--box">
          <div className="hero--section--content">
            <p className="section--title">แบคโฮเล็ก</p>
            <h1 className="skills-section--heading">PC60</h1>
            <p className="hero--section-description">
            ถแบคโฮขนาด PC60 เป็นรถขุดที่มีขนาดกลางและมีความสามารถหลากหลายในการใช้งานในงานก่อสร้างและงานเกษตรกรรม ซึ่งสามารถทำงานที่ต้องการพลังมากขึ้นและการขุดลึกกว่ารถแบคโฮขนาดเล็ก เช่น PC30 โดยทั่วไปแล้ว รถแบคโฮรุ่น PC60 สามารถทำงานดังต่อไปนี้ได้:<p></p>
            ขุดดินลึกขึ้น: เหมาะสำหรับการขุดหลุมขนาดใหญ่และลึกกว่ารถแบคโฮขนาดเล็ก เช่น การขุดหลุมสำหรับวางรากฐานอาคารหรือโครงการก่อสร้างขนาดใหญ่
ปรับพื้นที่: มีความสามารถในการปรับพื้นที่ที่กว้างและลึกขึ้น การเตรียมพื้นที่สำหรับงานก่อสร้างขนาดกลางถึงใหญ่
ย้ายดินและวัสดุจำนวนมาก: ด้วยกำลังที่มากขึ้น สามารถขนย้ายดิน หิน และวัสดุก่อสร้างในปริมาณที่มากขึ้นและหนักกว่า
งานรื้อถอนขนาดกลาง: ใช้ในการรื้อถอนสิ่งก่อสร้างที่มีขนาดใหญ่ขึ้น หรือโครงสร้างที่ต้องการพลังในการทำลายมากกว่า
งานเกษตรและป่าไม้: เหมาะสำหรับการขุดร่องน้ำ คูคลองขนาดใหญ่ หรือการเตรียมดินในพื้นที่การเกษตรที่มีขนาดใหญ่กว่า
ขุดเจาะ: สามารถติดตั้งอุปกรณ์เสริมสำหรับงานขุดเจาะที่ต้องการความลึกและพลังมากขึ้น เช่น เจาะหิน หรือต้นไม้ขนาดใหญ่
            </p>
            <p className="hero--section-description">
            แบคโฮ (รถแม็คโคร) ขนาดรุ่น PC60 บริการทั้งแบบรายวันและรายเเดือน 
            พร้อมทั้งคนขับรถที่มีประสบการณ์ ปรึกษาได้ตลอด 24 ชั่วโมง
            โทร.<a href="tel:0876813472">0876813472</a>และ<a href="tel:0922736082">0922736082</a>
            </p>
          </div>
        </div>
        <div className="about--section--img">
        <video width="550" height="300" controls autoPlay loop>
          <source src="../img/pc60.webm" type="video/webm" />
          <source src="../img/pc60.mp4" type="video/mp4" />
        </video>
        </div>
      </section>
    );
  }
  