export default function AboutMe() {
  return (
    <section id="AboutMe" className="about--section">
      <div className="about--section--img">
        <img src="./img/logo.png" alt="About Me" />
      </div>
      <div className="hero--section--content--box about--section--box">
        <div className="hero--section--content">
          <p className="section--title">เกี่ยวกับเรา</p>
          <h1 className="skills-section--heading">งานที่ต้องใช้แบคโฮ</h1>
          <p className="hero--section-description">
            การใช้รถแบคโฮ หรือรถแม็คโครในงานก่อสร้างและการเกษตรมีหลายประเภท ดังนี้ ขุดดิน
            ขุดบ่อเกษตร ขุดสระ ขุดวางถังบำบัด ยกของ ย้ายวัสดุก่อสร้าง วัสดุการเกษคร กดเสาเข็มคอมไพล์
            เครียลิ้งพื้นที่ก่อนก่อสร้าง เจาะพื้นคอนกรีต เจาะพื่นถนน.
          </p>
          <p className="hero--section-description">
            พื้นที่ให้บริการ กรุงเทพมหานคร นนทบุรี อยุธยา สุพรรณบุรี สระบุรี นครนายก
            ปทุมธานี ฉะเชิงเทรา ชลบุรี สมุทรปราการ สมุทรสาคร สมุทรสงคราม นครปฐม.
          </p>
        </div>
      </div>
    </section>
  );
}
