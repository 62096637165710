export default function Pc30() {
  return (
    <section id="AboutMe" className="about--section">
      <div className="about--section--img">
        <img src="./img/pc30.webp" alt="แบคโฮ PC30" />
      </div>
      <div className="hero--section--content--box about--section--box">
        <div className="hero--section--content">
          <p className="section--title">แบคโฮ</p>
          <h1 className="skills-section--heading">PC30</h1>
          <p className="hero--section-description">
          รถแบคโฮรุ่น PC30 เป็นรถขุดขนาดเล็กที่มีความคล่องตัวสูง 
          ใช้ในงานก่อสร้างและงานเกษตรกรรมได้หลายประเภท ฃ
          รวมถึงการทำงานในพื้นที่จำกัดที่รถขุดขนาดใหญ่ไม่สามารถเข้าถึงได้ 
          โดยทั่วไปแล้ว รถแบคโฮรุ่น PC30 สามารถ ขุดดิน ปรับพื้นที่่ ย้ายดินหรือวัสดุ
          รื้อถอนสิ่งก่อสร้าง งานเกษตร ขุดเจาะ
          </p>
          <p className="hero--section-description">
            แบคโฮ (รถแม็คโคร) ขนาดรุ่น PC30 บริการทั้งแบบรายวันและรายเเดือน 
            พร้อมทั้งคนขับรถที่มีประสบการณ์ ปรึกษาได้ตลอด 24 ชั่วโมง
            โทร.<a href="tel:0876813472">0876813472</a>และ<a href="tel:0922736082">0922736082</a>
          </p>
        </div>
      </div>
    </section>
  );
}
