export default function PC120() {
    return (
      <section id="AboutMe" className="about--section">
        <div className="about--section--img">
          <img src="./img/6wheel.webp" alt="หกล้อดัมพ์" />
        </div>
        <div className="hero--section--content--box about--section--box">
          <div className="hero--section--content">
            <p className="section--title">ให้เช่า</p>
            <h1 className="skills-section--heading">6ล้อ และกระบะดั๊มพ์</h1>
            <p className="hero--section-description">
            การใช้รถ 6 ล้อและกระบะดั๊มพ์ควบคู่กับงานแบคโฮมีประโยชน์มากมาย ดังนี้:
<p>1. การขนย้ายวัสดุอย่างมีประสิทธิภาพ</p>
<p>รถ 6 ล้อ: สามารถขนย้ายวัสดุอย่างดิน หิน ทราย และวัสดุก่อสร้างอื่น ๆ ได้ในปริมาณมาก ทำให้การขนย้ายวัสดุจากจุดขุดไปยังจุดปล่อยทำได้รวดเร็วและมีประสิทธิภาพ</p>
<p>กระบะดั๊มพ์: ช่วยให้การขนถ่ายวัสดุทำได้สะดวกและรวดเร็ว โดยสามารถยกกระบะเพื่อทิ้งวัสดุในจุดที่ต้องการได้ทันที ลดเวลาการขนถ่ายวัสดุลง</p>
<p>2. การทำงานที่ต่อเนื่องและลดเวลาหยุดชะงัก</p>
<p>รถ 6 ล้อ: สามารถใช้ในการขนส่งวัสดุไปยังไซต์งานและออกจากไซต์งานได้อย่างต่อเนื่อง ทำให้แบคโฮสามารถทำงานขุดได้โดยไม่ต้องหยุดเพื่อรอการขนย้ายวัสดุ</p>
<p>กระบะดั๊มพ์: การใช้กระบะดั๊มพ์ช่วยลดเวลาการขนถ่ายวัสดุ ทำให้สามารถกลับมารับวัสดุใหม่ได้เร็วขึ้น ส่งผลให้การทำงานของแบคโฮมีประสิทธิภาพมากขึ้น</p>
<p>3. การลดแรงงานและค่าใช้จ่าย</p>
<p>การใช้รถ 6 ล้อและกระบะดั๊มพ์ช่วยลดการใช้แรงงานคนในการขนย้ายวัสดุ ทำให้ประหยัดค่าใช้จ่ายในการจ้างแรงงาน</p>
<p>การใช้เครื่องจักรในการขนย้ายวัสดุทำให้การทำงานเสร็จเร็วขึ้น ลดเวลาการทำงานลง และลดค่าใช้จ่ายรวม</p>
<p>4. การเข้าถึงพื้นที่งานที่ยาก</p>
<p>รถ 6 ล้อ: มีความคล่องตัวสูง สามารถเข้าถึงพื้นที่งานที่ยากหรือจำกัดได้ง่าย ทำให้เหมาะสมกับการทำงานในไซต์งานก่อสร้างขนาดเล็กหรือในพื้นที่ที่จำกัด</p>
<p>กระบะดั๊มพ์: สามารถขนถ่ายวัสดุในพื้นที่ที่ยากต่อการเข้าถึงหรือมีพื้นที่จำกัดได้อย่างมีประสิทธิภาพ</p>
<p>5. การเพิ่มประสิทธิภาพในการจัดการงาน</p>
<p>การใช้รถ 6 ล้อและกระบะดั๊มพ์ควบคู่กับงานแบคโฮช่วยเพิ่มความสามารถในการจัดการงานในไซต์งาน ช่วยให้สามารถควบคุมและจัดการการขนย้ายวัสดุได้ดียิ่งขึ้น</p>
<p>ทำให้สามารถประเมินและปรับแผนการทำงานได้อย่างแม่นยำและรวดเร็ว</p>
<p>การใช้รถ 6 ล้อและกระบะดั๊มพ์ควบคู่กับงานแบคโฮจึงเป็นการเพิ่มประสิทธิภาพในการทำงาน ลดเวลาการทำงานและค่าใช้จ่าย รวมถึงทำให้การขนย้ายวัสดุทำได้ง่ายและรวดเร็วมากยิ่งขึ้น</p>
            </p>
          </div>
        </div>
      </section>
    );
  }
  